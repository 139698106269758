<template>
  <div>
    <my-header></my-header>
    <div class="box-box">
      <div class="detail">
        <evaluate :item="item"></evaluate>
        <div class="input">
          <el-input
            type="textarea"
            :rows="4"
            placeholder="请输入内容"
            v-model="content"
            :maxlength="200"
          >
          </el-input>
          <div class="input-bottom">
            <span>共可输入200字</span>
            <span @click="addComment">提交</span>
          </div>
        </div>
        <div class="bottom">
          <div
            class="bottom-list"
            v-for="(v, k) of productCommentList"
            :key="k"
          >
            <div>
              <span v-if="v.childNickName"
                ><span>{{ v.childNickName }}</span
                ><span>回复</span>
              </span>
              <span v-else></span>
              <span>{{ v.nickName }}</span> : <span>{{ v.content }}</span>
            </div>
            <div>
              <span>{{ v.createTime }}</span
              ><span>
                <span
                  @click="
                    CommentshowIndex = k;
                    Commentshow = !Commentshow;
                  "
                  :style="{ padding: '3px' }"
                  >回复</span
                >
                <span
                  v-if="
                    v.childUserId === $store.state.userinfo.id ||
                    v.userId === $store.state.userinfo.id
                  "
                  :style="{ padding: '3px' }"
                  @click="delectComment(v)"
                  >删除</span
                ></span
              >
            </div>
            <div>
              <el-collapse-transition>
                <div v-show="CommentshowIndex == k && Commentshow">
                  <div class="input" :style="{ margin: '0', marginTop: '8px' }">
                    <el-input
                      type="textarea"
                      :rows="4"
                      placeholder="请输入内容"
                      v-model="subContent"
                      :maxlength="200"
                    >
                    </el-input>
                    <div class="input-bottom">
                      <span>共可输入200字</span>
                      <span @click="addproductComment(v)">提交</span>
                    </div>
                  </div>
                </div>
              </el-collapse-transition>
            </div>
          </div>
          <!-- 分页 -->
          <el-pagination
            :style="{
              textAlign: 'right',
              margin: '30px 0px',
              paddingRight: '10px',
            }"
            background
            layout="prev, pager, next"
            :total="total"
            @current-change="changepage"
            hide-on-single-page
          >
          </el-pagination>
        </div>
      </div>
      <div class="productDetail">
        <div>商品信息</div>
        <div>
          <div><img :src="productDetail.mainImage" alt="" /></div>
          <div>
            <span>商品名称:</span><span>{{ productDetail.name }}</span>
          </div>
          <div>
            <span>厨盟价:</span><span>{{ productDetail.price }}</span>
          </div>
          <div>
            <span @click="$router.back(-1)">查看详情</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import evaluate from "./datailFourEvaluate";
export default {
  inject: ["reload"],
  components: { evaluate },
  data() {
    return {
      // 当前商品详情
      productDetail: "",
      // 当前打开子评论输入框index
      CommentshowIndex: 0,
      //回复子评论输入框显示隐藏
      Commentshow: false,
      // 主评论下所有子评论
      productCommentList: [],
      //主评论内容
      item: "",
      //根据商品子评论添加回复内容
      subContent: "",
      //根据商品评价添加回复内容
      content: "",
      //子评论page项
      pageNum: 1,
      pageSize: 10,
      total: 0,
    };
  },
  methods: {
    //获取主评论内容
    getItem() {
      this.item = JSON.parse(this.$route.query.item);
    },
    // 分页页数改变时
    changepage(pageNum) {
      this.pageNum = pageNum;
      this.getproductComment();
    },
    // 查询当前商品参数
    async getproductDetail() {
      const res = await this.$http({
        method: "get",
        url: "front/product/findById",
        params: {
          id: this.item.productId,
        },
      });
      if (res.data.status == 200) {
        this.productDetail = res.data.data;
        console.log(this.productDetail);
      }
    },
    //查询主评论下所有子评论
    async getproductComment() {
      const res = await this.$http({
        method: "get",
        url: "front/productComment/findList",
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          mainId: this.item.id,
          orderByType: "create_time desc",
        },
      });
      if (res.data.status == 200) {
        console.log(res.data.data);
        this.productCommentList = res.data.data.list;
        this.total = res.data.data.total;
      }
    },
    // 根据商品子评论添加回复
    async addproductComment(v) {
      const res = await this.$http({
        method: "post",
        url: "front/productComment/addChild",
        data: {
          parentId: v.parentId,
          mainId: v.mainId,
          content: this.subContent,
          userId: v.userId,
          nickName: v.nickName,
        },
      });
      if (res.data.status == 200) {
        console.log(res.data);
        this.$message({
          message: "回复成功",
          type: "success",
        });
        this.reload();
      }
    },
    //根据商品主评价添加回复
    async addComment() {
      let nickName = "";
      if (this.$store.state.userinfo.nickName == null) {
        nickName = this.$store.state.userinfo.phone;
      } else {
        nickName = this.$store.state.userinfo.nickName;
      }
      const res = await this.$http({
        method: "post",
        url: "front/productComment/saveByMainId",
        data: {
          mainId: this.item.id,
          content: this.content,
          nickName: nickName,
        },
      });
      if (res.data.status == 200) {
        console.log(res.data);
        this.$message({
          message: "回复成功",
          type: "success",
        });
        this.reload();
      }
    },
    // 删除评论
    async delectComment(v) {
      const res = await this.$http({
        method: "post",
        url: "front/productComment/delete",
        data: {
          id: v.id,
        },
      });
      console.log(res.data);
      if (res.data.status == 200) {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.reload();
      }
    },
  },
  created() {
    this.getItem();
    this.getproductComment();
    this.getproductDetail();
  },
};
</script>
<style lang="scss" scoped>
* {
  font: 12px/150% tahoma, arial, Microsoft YaHei, Hiragino Sans GB,
    "\u5b8b\u4f53", sans-serif;
  color: #666;
}
.box-box {
  width: 1200px;
  margin: 0 auto;
  margin-top: 160px;
  display: flex;
  .detail {
    border: 1px solid #f2f2f2;
    flex: 9;
    .input {
      padding: 10px;
      border: 1px solid #eee;
      background-color: #f7f7f7;
      margin: 10px;
      margin-left: 130px;
      .input-bottom {
        line-height: 25px;
        margin-top: 10px;
        text-align: right;
        & > span:nth-child(1) {
          margin-right: 10px;
          color: #999;
          display: inline-block;
        }
        & > span:nth-child(2) {
          padding: 5px 28px;
          background: #e4393c;
          color: #fff;
          margin-right: 0;
          display: inline-block;
          border-radius: 2px;
          font-family: "Microsoft YaHei";
          cursor: pointer;
        }
      }
    }
    .bottom-list {
      margin-left: 130px;
      padding: 15px;
      border-bottom: 1px solid #f0f0f0;
      & > div:nth-child(1) {
        line-height: 15px;
        color: #666;
        & > span:nth-child(1) {
          & > span:nth-child(1) {
            color: #005ea7;
          }
          & > span:nth-child(2) {
            padding: 3px;
          }
        }
        & > span:nth-child(2) {
          color: #005ea7;
        }
      }
      & > div:nth-child(2) {
        color: #666;
        display: flex;
        justify-content: space-between;
        line-height: 26px;
        margin-top: 3px;
        & > span:nth-child(2) {
          cursor: pointer;
          & > span:hover {
            color: #c81623;
          }
        }
      }
    }
  }
  .productDetail {
    flex: 1.85;
    height: 200px;
    margin-left: 10px;

    & > div:nth-child(1) {
      height: 30px;
      line-height: 30px;
      color: #333;
      padding-left: 10px;
      font-family: \5fae\8f6f\96c5\9ed1;
      font-weight: 400;
      font-size: 14px;
      background: #f7f7f7;
      border: 1px solid #f2f2f2;
      border-bottom: 0;
    }
    & > div:nth-child(2) {
      padding: 10px;
      border: 1px solid #f2f2f2;
      border-top: none;
      & > div {
        margin: 5px 0px;
      }
      & > div:nth-child(1) {
        margin: 0 auto;
        width: 80%;
        img {
          width: 100%;
        }
      }
      & > div:nth-child(2) {
        & > span:nth-child(2) {
          color: #005aa0;
        }
      }
      & > div:nth-child(3) {
        & > span:nth-child(2) {
          color: #e4393c;
          font-weight: bold;
        }
      }
      & > div:nth-child(4) {
        text-align: center;
        margin-top: 5px;
        & > span:nth-child(1) {
          border-radius: 4px;
          font-size: 16px;
          font-weight: bold;
          height: 36px;
          line-height: 36px;
          padding: 0 30px;
          background-color: #df3033;
          color: #fff;
          display: inline-block;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
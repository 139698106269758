var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('my-header'),_c('div',{staticClass:"box-box"},[_c('div',{staticClass:"detail"},[_c('evaluate',{attrs:{"item":_vm.item}}),_c('div',{staticClass:"input"},[_c('el-input',{attrs:{"type":"textarea","rows":4,"placeholder":"请输入内容","maxlength":200},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}}),_c('div',{staticClass:"input-bottom"},[_c('span',[_vm._v("共可输入200字")]),_c('span',{on:{"click":_vm.addComment}},[_vm._v("提交")])])],1),_c('div',{staticClass:"bottom"},[_vm._l((_vm.productCommentList),function(v,k){return _c('div',{key:k,staticClass:"bottom-list"},[_c('div',[(v.childNickName)?_c('span',[_c('span',[_vm._v(_vm._s(v.childNickName))]),_c('span',[_vm._v("回复")])]):_c('span'),_c('span',[_vm._v(_vm._s(v.nickName))]),_vm._v(" : "),_c('span',[_vm._v(_vm._s(v.content))])]),_c('div',[_c('span',[_vm._v(_vm._s(v.createTime))]),_c('span',[_c('span',{style:({ padding: '3px' }),on:{"click":function($event){_vm.CommentshowIndex = k;
                  _vm.Commentshow = !_vm.Commentshow;}}},[_vm._v("回复")]),(
                  v.childUserId === _vm.$store.state.userinfo.id ||
                  v.userId === _vm.$store.state.userinfo.id
                )?_c('span',{style:({ padding: '3px' }),on:{"click":function($event){return _vm.delectComment(v)}}},[_vm._v("删除")]):_vm._e()])]),_c('div',[_c('el-collapse-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.CommentshowIndex == k && _vm.Commentshow),expression:"CommentshowIndex == k && Commentshow"}]},[_c('div',{staticClass:"input",style:({ margin: '0', marginTop: '8px' })},[_c('el-input',{attrs:{"type":"textarea","rows":4,"placeholder":"请输入内容","maxlength":200},model:{value:(_vm.subContent),callback:function ($$v) {_vm.subContent=$$v},expression:"subContent"}}),_c('div',{staticClass:"input-bottom"},[_c('span',[_vm._v("共可输入200字")]),_c('span',{on:{"click":function($event){return _vm.addproductComment(v)}}},[_vm._v("提交")])])],1)])])],1)])}),_c('el-pagination',{style:({
            textAlign: 'right',
            margin: '30px 0px',
            paddingRight: '10px',
          }),attrs:{"background":"","layout":"prev, pager, next","total":_vm.total,"hide-on-single-page":""},on:{"current-change":_vm.changepage}})],2)],1),_c('div',{staticClass:"productDetail"},[_c('div',[_vm._v("商品信息")]),_c('div',[_c('div',[_c('img',{attrs:{"src":_vm.productDetail.mainImage,"alt":""}})]),_c('div',[_c('span',[_vm._v("商品名称:")]),_c('span',[_vm._v(_vm._s(_vm.productDetail.name))])]),_c('div',[_c('span',[_vm._v("厨盟价:")]),_c('span',[_vm._v(_vm._s(_vm.productDetail.price))])]),_c('div',[_c('span',{on:{"click":function($event){return _vm.$router.back(-1)}}},[_vm._v("查看详情")])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }